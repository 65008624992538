<template>
  <div class="detail">
    <div class="success">
      <img src="../assets/error-icon.png" alt="" />
      <p class="tit">支付失败！</p>
      <p>抱歉，您支付失败</p>
    </div>
    <div class="attention">
      <img
        src="https://lecare-insurance.oss-cn-beijing.aliyuncs.com/prod/applet/160017.jpg"
        show-menu-by-longpress
        alt=""
      />
      <p class="p1"><span>长按识别二维码</span>，关注公众号</p>
      <p class="p2">在【我的订单】查看订单</p>
      <p class="btn" @click="attention">去关注</p>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    //去关注
    attention() {
      window.location.href =
        "https://mp.weixin.qq.com/mp/profile_ext?action=home&__biz=Mzg2NjY2MzAwNg==&scene=110#wechat_redirect";
    },
  },
};
</script>

<style lang="scss" scoped>
.detail {
  .success {
    padding: 24px 0 42px;
    background: #ff6c40;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 58px;
    }
    .tit {
      font-size: 17px;
      font-weight: bold;
      margin-top: 17px;
      color: #ffffff;
      line-height: 24px;
    }
    p:last-child {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
      margin-top: 10px;
    }
  }
  .attention {
    margin-top: 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 152px;
    }
    .p1 {
      margin-top: 11px;
      font-size: 13px;
      font-weight: bold;
      color: #666;
      line-height: 22px;
      span {
        color: #ff6c40;
      }
    }
    .p2 {
      font-size: 13px;
      font-weight: bold;
      color: #666;
      line-height: 22px;
    }
    .btn {
      width: 191px;
      height: 45px;
      background: #ff6c40;
      border-radius: 23px;
      font-size: 15px;
      font-weight: bold;
      color: #ffffff;
      line-height: 21px;
      display: flex;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      margin-top: 49px;
    }
  }
}
</style>